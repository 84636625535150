import { Link } from "react-router-dom";

const Kyiv = () => {
    const locationURL = 'https://maps.app.goo.gl/qg8ByYPqTibE125b6';


    const openMap = (e) => {
      e.preventDefault();
      window.open(locationURL, '_blank');
    };

    return ( <main>
        <ul className="container-example-video">
                <li className="container-item">
                  
                  <iframe title="My Video" style={{ width: '100%' }} src="https://www.youtube.com/embed/majnoer4i6o" allowFullScreen></iframe>
                   
                </li>
                <li className="container-item">
          <a href={locationURL} rel="noopener noreferrer" onClick={openMap}>
            <img src="/location-4-svgrepo-com.svg" alt="Location Icon" width="40" height="40" /> 
          </a>
        </li>
                <li className="container-list-buttons">
                    <div className="main-container">
                        <button> <Link to="/connect"><span className="button-text">Connect</span></Link></button>
                        <button> <Link to="/payment"><span className="button-text">Payment</span></Link></button>
                        
                    </div>
                </li>
        </ul>

    </main> );
}
 
export default Kyiv;