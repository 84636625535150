import { Link } from "react-router-dom";

const NewYork = () => {
    return ( <main>
        <div className="main-container">
            <button > <Link to="/countries/usa/city-new-york/Freedom"><span className="button-text">Freedom</span></Link>
            </button>
            <button > <Link to="/countries/usa/city-new-york/TimesSquare"><span className="button-text">Times Square</span></Link>
            </button>
            <button > <Link to="/countries/usa/city-new-york/CentralPark"><span className="button-text">Central Park</span></Link>
            </button>
                                    
        </div>
    </main> );
}
 
export default NewYork;