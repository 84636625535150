import { Link } from "react-router-dom";

const China = () => {
    return ( 
    <main>
        <div className="main-container">
            <button> <Link to="/countries/china/GreatWall"><span className="button-text">Great Wall</span></Link>
            </button>
            <button> <Link to="/countries/china/SanqingMountain"><span className="button-text">Sanqing Mountain</span></Link>
            </button>
            <button> <Link to="/countries/china/Shanghai"><span className="button-text">Shanghai</span></Link>
            </button>
            <button> <Link to="/countries/china/TianmenMountain"><span className="button-text">Tianmen Mountain</span></Link>
            </button>
        </div>
    </main> );
}
 
export default China;