import { Link } from "react-router-dom";

const Morocco = () => {
    return ( 
    <main>
        <div className="main-container">
            <button> <Link to="/countries/morocco/LeatherDyers"><span className="button-text">leather dyers</span></Link>
            </button>
            <button> <Link to="/countries/morocco/Tangier"><span className="button-text">Tangier</span></Link>
            </button>
            <button> <Link to="/countries/morocco/HassanIIMosque"><span className="button-text">Hassan II Mosque</span></Link>
            </button>
            
            
        </div>
    </main> );
}
 
export default Morocco;