import { Link } from "react-router-dom";

const Australia = () => {
    return ( 
    <main>
        <div className="main-container">
            <button> <Link to="/countries/australia/HarborBridge"><span className="button-text">Harbor Bridge</span></Link>
            </button>
            <button> <Link to="/countries/australia/Opera"><span className="button-text">Opera</span></Link>
            </button>
            <button> <Link to="/countries/australia/PrincesBridge"><span className="button-text">Princes Bridge</span></Link>
            </button>
            
        </div>
    </main> );
}
 
export default Australia;