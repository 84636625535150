import { Link } from "react-router-dom";

const Countries = () => {
    return ( 
        <main>
        <div className="main-container">
            <button> <Link to="/countries/australia/Australia"><span className="button-text">Australia</span></Link>
            </button>
            <button> <Link to="/countries/austria/Austria"><span className="button-text">Austria</span></Link>
            </button>
            <button> <Link to="/countries/brazil/Brazil"><span className="button-text">Brazil</span></Link>
            </button>
            <button> <Link to="/countries/canada/Canada"><span className="button-text">Canada</span></Link>
            </button>
            <button> <Link to="/countries/china/China"><span className="button-text">China</span></Link>
            </button>
            <button> <Link to="/countries/dubai/Dubai"><span className="button-text">Dubai</span></Link>
            </button>
            <button> <Link to="/countries/england/England"><span className="button-text">England</span></Link>
            </button>
            <button> <Link to="/countries/france/France"><span className="button-text">France</span></Link>
            </button>
            <button> <Link to="/countries/greece/Greece"><span className="button-text">Greece</span></Link>
            </button>
            <button> <Link to="/countries/india/India"><span className="button-text">India</span></Link>
            </button>
            <button> <Link to="/countries/indonesia/Indonesia"><span className="button-text">Indonesia</span></Link>
            </button>
            <button> <Link to="/countries/italy/Italy"><span className="button-text">Italy</span></Link>
            </button>
            <button> <Link to="/countries/japan/Japan"><span className="button-text">Japan</span></Link>
            </button>
            <button> <Link to="/countries/malaysia/Malaysia"><span className="button-text">Malaysia</span></Link>
            </button>
            <button> <Link to="/countries/mexico/Mexico"><span className="button-text">Mexico</span></Link>
            </button>
            <button> <Link to="/countries/morocco/Morocco"><span className="button-text">Morocco</span></Link>
            </button>
            <button> <Link to="/countries/newzealand/NewZealand"><span className="button-text">New Zealand</span></Link>
            </button>
            <button> <Link to="/countries/norway/Norway"><span className="button-text">Norway</span></Link>
            </button>
            <button> <Link to="/countries/singapore/Singapore"><span className="button-text">Singapore</span></Link>
            </button>
            <button> <Link to="/countries/spain/Spain"><span className="button-text">Spain</span></Link>
            </button>
            <button> <Link to="/countries/switzerland/Switzerland"><span className="button-text">Switzerland</span></Link>
            </button>
            <button> <Link to="/countries/thailand/Thailand"><span className="button-text">Thailand</span></Link>
            </button>
            <button> <Link to="/countries/turkey/Turkey"><span className="button-text">Turkey</span></Link>
            </button>
            <button> <Link to="/countries/ukraine/Ukraine"><span className="button-text">Ukraine</span></Link>
            </button>         
            <button> <Link to="/countries/usa/USA"><span className="button-text">USA</span></Link>
            </button>
            <button> <Link to="/countries/vietnam/Vietnam"><span className="button-text">Vietnam</span></Link>
            </button>         
                       
                         
            
            
            
           
            
            
        </div>
    </main>
     );
}
 
export default Countries;