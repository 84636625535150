import { Link } from "react-router-dom";

const California = () => {
    return ( <main>
        <div className="main-container">
            <button > <Link to="/countries/usa/city-california/Hollywood"><span className="button-text">Hollywood</span></Link>
            </button>
            <button > <Link to="/countries/usa/city-california/GoldenGateBridge"><span className="button-text">Golden Gate Bridge</span></Link>
            </button>
            
                        
        </div>
    </main> );
}
 
export default California;