import './App.css';
import './index.css';
import {BrowserRouter as Router, Routes, Route} from"react-router-dom";
import Footer from './components/footer/Footer';
import Header from "./components/header/Header";
import Home from './pages/Home';
import Profile from './pages/Profile';
import Informationabout from './pages/Informationabout';
import RegistrationForm from './pages/RegistrationForm';
import Instruction from './pages/Instruction';
import Freelocations from './pages/Freelocations';
import Countries from './pages/countries/Countries';
import Ukraine from './pages/countries/ukraine/Ukraine';
import Kyiv from './pages/countries/ukraine/city-kyiv/Kyiv';
import Connect from './pages/Connect';
import Payment from './pages/Payment';
import Gallery from './pages/Gallery';
import Contacts from './pages/Contacts';
import Franchise from './pages/Franchise';
import Spain from './pages/countries/spain/Spain';
import Barcelona from './pages/countries/spain/city-barcelona/Barcelona';
import Church from './pages/countries/spain/city-barcelona/Church';
import Parkguell from './pages/countries/spain/city-barcelona/Parkguell';
import Greece from './pages/countries/greece/Greece';
import Navagio from './pages/countries/greece/Navagio';
import Cordova from './pages/countries/spain/Cordova';
import Parthenon from './pages/countries/greece/Parthenon';
import USA from './pages/countries/usa/USA';
import California from './pages/countries/usa/city-california/California';
import Hollywood from './pages/countries/usa/city-california/Hollywood';
import GoldenGateBridge from './pages/countries/usa/city-california/GoldenGateBridge';
import NewYork from './pages/countries/usa/city-new-york/NewYork';
import Freedom from './pages/countries/usa/city-new-york/Freedom';
import TimesSquare from './pages/countries/usa/city-new-york/TimesSquare';
import CentralPark from './pages/countries/usa/city-new-york/CentralPark';
import GrandCanyon from './pages/countries/usa/GrandCanyon';
import Turkey from './pages/countries/turkey/Turkey';
import Istanbul from './pages/countries/turkey/Istanbul';
import Bosphorus from './pages/countries/turkey/Bosphorus';
import Singapore from './pages/countries/singapore/Singapore';
import MarinaBaySands from './pages/countries/singapore/MarinaBaySands';
import ParkRoyal from './pages/countries/singapore/ParkRoyal';
import Italy from './pages/countries/italy/Italy';
import Pisa from './pages/countries/italy/Pisa';
import Venice from './pages/countries/italy/Venice';
import Rome from './pages/countries/italy/Rome';
import Mexico from './pages/countries/mexico/Mexico';
import Palenque from './pages/countries/mexico/Palenque';
import Bursatil from './pages/countries/mexico/Bursatil';
import Brazil from './pages/countries/brazil/Brazil';
import Christ from './pages/countries/brazil/Christ';
import Iguazu from './pages/countries/brazil/Iguazu';
import India from './pages/countries/india/India';
import TajMahal from './pages/countries/india/TajMahal';
import Varanasi from './pages/countries/india/Varanasi';
import Jaipur from './pages/countries/india/Jaipur';
import Vietnam from './pages/countries/vietnam/Vietnam';
import Buddha from './pages/countries/vietnam/Buddha';
import GoldenBridge from './pages/countries/vietnam/GoldenBridge';
import England from './pages/countries/england/England';
import BigBen from './pages/countries/england/BigBen';
import Stonehenge from './pages/countries/england/Stonehenge';
import France from './pages/countries/france/France';
import Louvre from './pages/countries/france/Louvre';
import EiffelTower from './pages/countries/france/EiffelTower';
import Canada from './pages/countries/canada/Canada';
import Niagara from './pages/countries/canada/Niagara';
import Banff from './pages/countries/canada/Banff';
import China from './pages/countries/china/China';
import GreatWall from './pages/countries/china/GreatWall';
import SanqingMountain from './pages/countries/china/SanqingMountain';
import Shanghai from './pages/countries/china/Shanghai';
import TianmenMountain from './pages/countries/china/TianmenMountain';
import Norway from './pages/countries/norway/Norway';
import Pregestulen from './pages/countries/norway/Pregestulen';
import Stegastain from './pages/countries/norway/Stegastain';
import Australia from './pages/countries/australia/Australia';
import HarborBridge from './pages/countries/australia/HarborBridge';
import Opera from './pages/countries/australia/Opera';
import PrincesBridge from './pages/countries/australia/PrincesBridge';
import Malaysia from './pages/countries/malaysia/Malaysia';
import KualaLumpur from './pages/countries/malaysia/KualaLumpur';
import LangkawiSkyBridge from './pages/countries/malaysia/LangkawiSkyBridge';
import Hawaii from './pages/countries/usa/hawaii/Hawaii';
import AlohaTower from './pages/countries/usa/hawaii/AlohaTower';
import DiamondHead from './pages/countries/usa/hawaii/DiamondHead';
import Kauai from './pages/countries/usa/hawaii/Kauai';
import Austria from './pages/countries/austria/Austria';
import FiveFingers from './pages/countries/austria/FiveFingers';
import Hallstatt from './pages/countries/austria/Hallstatt';
import Japan from './pages/countries/japan/Japan';
import Fuji from './pages/countries/japan/Fuji';
import TokyoShibuia from './pages/countries/japan/TokyoShibuia';
import Dubai from './pages/countries/dubai/Dubai';
import MuseumFuture from './pages/countries/dubai/MuseumFuture';
import BurjAlArab from './pages/countries/dubai/BurjAlArab';
import Switzerland from './pages/countries/switzerland/Switzerland';
import OberhofenCastle from './pages/countries/switzerland/OberhofenCastle';
import Bern from './pages/countries/switzerland/Bern';
import NewZealand from './pages/countries/newzealand/NewZealand';
import Queenstown from './pages/countries/newzealand/Queenstown';
import Hobbiton from './pages/countries/newzealand/Hobbiton';
import Morocco from './pages/countries/morocco/Morocco';
import LeatherDyers from './pages/countries/morocco/LeatherDyers';
import Tangier from './pages/countries/morocco/Tangier';
import HassanIIMosque from './pages/countries/morocco/HassanIIMosque';
import Thailand from './pages/countries/thailand/Thailand';
import AutthayaPark from './pages/countries/thailand/AutthayaPark';
import WatRongKhun from './pages/countries/thailand/WatRongKhun';
import Indonesia from './pages/countries/indonesia/Indonesia';
import Yogyakarta from './pages/countries/indonesia/Yogyakarta';
import TanahLot from './pages/countries/indonesia/TanahLot';


function App() {
  return (
    <div>
      <Router>
        
        <Header /> 
      <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='profile' element={<Profile/>} />
          <Route path='informationabout' element={<Informationabout/>} />
          <Route path='registrationForm' element={<RegistrationForm/>} />
          <Route path='freelocations' element={<Freelocations/>} />
          <Route path='/countries/Countries' element={<Countries />} />
          <Route path='/countries/ukraine/Ukraine' element={<Ukraine/>} />
          <Route path='/countries/ukraine/city-kyiv/Kyiv' element={<Kyiv/>} />
          <Route path='connect' element={<Connect/>} />
          <Route path='instruction' element={<Instruction/>} />
          <Route path='payment' element={<Payment/>} />
          <Route path='gallery' element={<Gallery/>} />
          <Route path='contacts' element={<Contacts/>} />
          <Route path='franchise' element={<Franchise/>} />
          <Route path='/countries/spain/Spain' element={<Spain/>} /> 
          <Route path='/countries/spain/city-barcelona/Barcelona' element={<Barcelona/>} />         
          <Route path='/countries/spain/city-barcelona/Church' element={<Church/>} />
          <Route path='/countries/spain/city-barcelona/Parkguell' element={<Parkguell/>} />
          <Route path='/countries/greece/Greece' element={<Greece/>} />
          <Route path='/countries/greece/Navagio' element={<Navagio/>} />
          <Route path='/countries/spain/Cordova' element={<Cordova/>} />
          <Route path='/countries/greece/Parthenon' element={<Parthenon/>} />
          <Route path='/countries/usa/USA' element={<USA/>} />
          <Route path='/countries/usa/city-california/California' element={<California/>} /> 
          <Route path='/countries/usa/city-california/Hollywood' element={<Hollywood/>} />
          <Route path='/countries/usa/city-california/GoldenGateBridge' element={<GoldenGateBridge/>} />
          <Route path='/countries/usa/city-new-york/NewYork' element={<NewYork/>} /> 
          <Route path='/countries/usa/city-new-york/Freedom' element={<Freedom/>} />
          <Route path='/countries/usa/city-new-york/TimesSquare' element={<TimesSquare/>} />
          <Route path='/countries/usa/city-new-york/CentralPark' element={<CentralPark/>} />
          <Route path='/countries/usa/GrandCanyon' element={<GrandCanyon/>} />
          <Route path='/countries/turkey/Turkey' element={<Turkey/>} />
          <Route path='/countries/turkey/Istanbul' element={<Istanbul/>} />
          <Route path='/countries/turkey/Bosphorus' element={<Bosphorus/>} />
          <Route path='/countries/singapore/Singapore' element={<Singapore/>} />
          <Route path='/countries/singapore/MarinaBaySands' element={<MarinaBaySands/>} />
          <Route path='/countries/singapore/ParkRoyal' element={<ParkRoyal/>} />
          <Route path='/countries/italy/Italy' element={<Italy/>} />
          <Route path='/countries/italy/Pisa' element={<Pisa/>} />
          <Route path='/countries/italy/Venice' element={<Venice/>} />
          <Route path='/countries/italy/Rome' element={<Rome/>} />
          <Route path='/countries/mexico/Mexico' element={<Mexico/>} />
          <Route path='/countries/mexico/Palenque' element={<Palenque/>} />
          <Route path='/countries/mexico/Bursatil' element={<Bursatil/>} />
          <Route path='/countries/brazil/Brazil' element={<Brazil/>} />
          <Route path='/countries/brazil/Christ' element={<Christ/>} />
          <Route path='/countries/brazil/Iguazu' element={<Iguazu/>} />
          <Route path='/countries/india/India' element={<India/>} />
          <Route path='/countries/india/TajMahal' element={<TajMahal/>} />
          <Route path='/countries/india/Varanasi' element={<Varanasi/>} />
          <Route path='/countries/india/Jaipur' element={<Jaipur/>} />
          <Route path='/countries/vietnam/Vietnam' element={<Vietnam/>} />
          <Route path='/countries/vietnam/Buddha' element={<Buddha/>} />
          <Route path='/countries/vietnam/GoldenBridge' element={<GoldenBridge/>} />
          <Route path='/countries/england/England' element={<England/>} />
          <Route path='/countries/england/BigBen' element={<BigBen/>} />
          <Route path='/countries/england/Stonehenge' element={<Stonehenge/>} />
          <Route path='/countries/france/France' element={<France/>} />
          <Route path='/countries/france/Louvre' element={<Louvre/>} />
          <Route path='/countries/france/EiffelTower' element={<EiffelTower/>} />
          <Route path='/countries/canada/Canada' element={<Canada/>} />
          <Route path='/countries/canada/Niagara' element={<Niagara/>} />
          <Route path='/countries/canada/Banff' element={<Banff/>} />
          <Route path='/countries/china/China' element={<China/>} />
          <Route path='/countries/china/GreatWall' element={<GreatWall/>} />
          <Route path='/countries/china/SanqingMountain' element={<SanqingMountain/>} />
          <Route path='/countries/china/Shanghai' element={<Shanghai/>} />
          <Route path='/countries/china/TianmenMountain' element={<TianmenMountain/>} />
          <Route path='/countries/norway/Norway' element={<Norway/>} />
          <Route path='/countries/norway/Pregestulen' element={<Pregestulen/>} />
          <Route path='/countries/norway/Stegastain' element={<Stegastain/>} />
          <Route path='/countries/australia/Australia' element={<Australia/>} />
          <Route path='/countries/australia/HarborBridge' element={<HarborBridge/>} />
          <Route path='/countries/australia/Opera' element={<Opera/>} />
          <Route path='/countries/australia/PrincesBridge' element={<PrincesBridge/>} />
          <Route path='/countries/malaysia/Malaysia' element={<Malaysia/>} />
          <Route path='/countries/malaysia/KualaLumpur' element={<KualaLumpur/>} />
          <Route path='/countries/malaysia/LangkawiSkyBridge' element={<LangkawiSkyBridge/>} />
          <Route path='/countries/usa/hawaii/Hawaii' element={<Hawaii/>} />
          <Route path='/countries/usa/hawaii/AlohaTower' element={<AlohaTower/>} />
          <Route path='/countries/usa/hawaii/DiamondHead' element={<DiamondHead/>} />
          <Route path='/countries/usa/hawaii/Kauai' element={<Kauai/>} />
          <Route path='/countries/austria/Austria' element={<Austria/>} />
          <Route path='/countries/austria/FiveFingers' element={<FiveFingers/>} />
          <Route path='/countries/austria/Hallstatt' element={<Hallstatt/>} />
          <Route path='/countries/japan/Japan' element={<Japan/>} />
          <Route path='/countries/japan/Fuji' element={<Fuji/>} />
          <Route path='/countries/japan/TokyoShibuia' element={<TokyoShibuia/>} />
          <Route path='/countries/dubai/Dubai' element={<Dubai/>} />
          <Route path='/countries/dubai/MuseumFuture' element={<MuseumFuture/>} />
          <Route path='/countries/dubai/BurjAlArab' element={<BurjAlArab/>} />
          <Route path='/countries/switzerland/Switzerland' element={<Switzerland/>} />
          <Route path='/countries/switzerland/OberhofenCastle' element={<OberhofenCastle/>} />
          <Route path='/countries/switzerland/Bern' element={<Bern/>} />
          <Route path='/countries/newzealand/NewZealand' element={<NewZealand/>} />
          <Route path='/countries/newzealand/Queenstown' element={<Queenstown/>} />
          <Route path='/countries/newzealand/Hobbiton' element={<Hobbiton/>} />
          <Route path='/countries/morocco/Morocco' element={<Morocco/>} />
          <Route path='/countries/morocco/LeatherDyers' element={<LeatherDyers/>} />
          <Route path='/countries/morocco/Tangier' element={<Tangier/>} />
          <Route path='/countries/morocco/HassanIIMosque' element={<HassanIIMosque/>} />
          <Route path='/countries/thailand/Thailand' element={<Thailand/>} />
          <Route path='/countries/thailand/AutthayaPark' element={<AutthayaPark/>} />
          <Route path='/countries/thailand/WatRongKhun' element={<WatRongKhun/>} />
          <Route path='/countries/indonesia/Indonesia' element={<Indonesia/>} />
          <Route path='/countries/indonesia/Yogyakarta' element={<Yogyakarta/>} />
          <Route path='/countries/indonesia/TanahLot' element={<TanahLot/>} />



      </Routes>
      
        
        <Footer />

      </Router>

      

    </div>

  );
}

export default App;
