import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Payment = () => {
  const [videoCount, setVideoCount] = useState(1);
  const [paidVideos, setPaidVideos] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState('');

  const handleButtonClick = async () => {
    const amount = 2.7 * videoCount;
    try {
      const response = await axios.post('http://localhost:5004/api/payment', { amount, userId: 'your_user_id', videoCount });
      setPaymentStatus(`Payment successful: ${response.data.message}`);
      await updatePaidVideos(); // Оновлюємо кількість оплачених відео після успішної оплати
    } catch (error) {
      console.error('Error creating payment:', error);
      setPaymentStatus('Payment failed');
    }
  };

  const updatePaidVideos = async () => {
    try {
      const response = await axios.get('http://localhost:5004/api/paid-videos', { params: { userId: 'your_user_id' } });
      console.log('Response data:', response.data); // Додаємо лог для перевірки відповіді з сервера
      setPaidVideos(response.data.count);
    } catch (error) {
      console.error('Error updating paid videos:', error);
    }
  };

  useEffect(() => {
    const fetchPaidVideos = async () => {
      try {
        const response = await axios.get('http://localhost:5004/api/paid-videos', { params: { userId: 'your_user_id' } });
        setPaidVideos(response.data.count);
      } catch (error) {
        console.error('Error fetching paid videos:', error);
      }
    };

    fetchPaidVideos();
  }, []);

  return (
    <main style={{ backgroundColor: "rgba(255, 200, 200, 0.8)", padding: "20px", borderRadius: "10px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
      <div className="main-container">
        
      <h2 className="cost">The cost of 1 video is $2.70</h2>
        <h2 className="paid-videos">Paid Videos: {paidVideos}</h2>
        <div className="video-count">
          <label>
            Number of videos:
            <input
              type="number"
              value={videoCount}
              onChange={(e) => setVideoCount(parseInt(e.target.value, 10))} // Уточнюємо тип даних
              min="1"
              step="1"
              style={{ width: "50px" }} // Зменшення ширини поля вводу
            />
          </label>
        </div>
        <button onClick={handleButtonClick} style={{ backgroundColor: "transparent", border: "none", padding: "0" }}>
          <img src="/google-pay-or-tez-logo-svgrepo-com.svg" alt="Google-Pay" width="80" height="80" />
        </button>
        <p>{paymentStatus}</p> {/* Додаємо повідомлення про статус платежу */}
        
      </div>
    </main>
  );
}

export default Payment;
