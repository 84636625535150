// import { Link } from "react-router-dom";

const Instruction = () => {
    return ( 
    <main>
        <div className="instruction">
        <p>The Distance Video project is made with Love for the planet Earth and people.</p>
                <p>Join the Distance Video project.</p>

                     
                <h3>Instructions for using the Distance Video application</h3> 
             <ol>
                <li>
                Download the Distance Video application from the Play Market or App Store.
                </li>
                <li>
                Register in the Distance Video application. 
                </li>
                <li>
                Pay for videos in the Distance Video application.
                </li>
                <li>
                Select a location in your country and city in the Distance Video application.
                </li>
                <li>
                Face the camera and press the START button in the Distance Video application.
                </li>
                <li>
                Wait 2 minutes to record a short video zooming in on you.
                </li>
                <li>
                Another 1 minute and you will receive the video in the gallery of the Distance Video program.
                </li>
             </ol>  
            </div>
    </main> );
}
 
export default Instruction;