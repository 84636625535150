import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Connect = () => {
  const [paidVideos, setPaidVideos] = useState(0);

  useEffect(() => {
    const fetchPaidVideos = async () => {
      try {
        const response = await axios.get('http://localhost:5004/api/paid-videos', { params: { userId: 'your_user_id' } });
        setPaidVideos(response.data.count);
      } catch (error) {
        console.error('Error fetching paid videos:', error);
      }
    };

    fetchPaidVideos();
  }, []);

  return (
    <main style={{ backgroundColor: "rgba(255, 200, 200, 0.8)", padding: "20px", borderRadius: "10px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
      <div className="main-container">
        <p className="button-instruction">
          Remote video recording. The stationary camera captures a panorama with the approach to the client.
          Video comes to your smartphone through the app.
          After payment, press the start button.
        </p>
        <h2 className="paid-videos">Paid Videos: {paidVideos}</h2>
        
        <button>
          <Link to=""><span className="button-text">START</span></Link>
        </button>
      </div>
    </main>
  );
}

export default Connect;
