// import { Link } from "react-router-dom";

const Informationabout = () => {
    return ( 
    <main>
        <div className="informationabout-fon">
        <ul className="ul-profile">
                <li className="li-profile">
                    <h4>Information about the launch of the Distance Video project</h4>
                </li>
                <li className="li-profile">
                    <p>Currently, test work is being carried out to connect video cameras to the application.</p>
                </li>
                <li className="li-profile">
                    <p>The start of the Distance Video project is scheduled for 2024.</p>
                </li>
                <li className="li-profile">
                    <p>All appeals should be made by e-mail: distance.v.office@gmail.com</p>
                </li>
            </ul>
            </div>
    </main> );
}
 
export default Informationabout;