// import { Link } from "react-router-dom";

const Gallery = () => {
    return ( 

        <main>
        <div className="gallery">
                    
            <ul>
                <li>
                <p> Here will be a list of saved videos.</p>
                <p> This video is an example. It was made with the help of a drone.  </p>
                </li>
                <li>
                <iframe title="Your iframe title" style={{ width: '100%' }}  src="https://www.youtube.com/embed/majnoer4i6o" frameBorder="0" allowFullScreen></iframe>
                </li>
            </ul>
        </div>
        </main>


     );
}
 
export default Gallery;