import { Link } from "react-router-dom";

const Spain = () => {
    return ( 
    <main>
        <div className="main-container">
            <button> <Link to="/countries/spain/city-barcelona/Barcelona"><span className="button-text">Barcelona</span></Link>
            </button>
            <button> <Link to="/countries/spain/Cordova"><span className="button-text">Cordova</span></Link>
            </button>
                        
        </div>
    </main> );
}
 
export default Spain;