import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  return (
    <header id="fixed-header">
      <h1 className="Distance-Video">
        <Link to="/">Distance Video</Link>
      </h1>
      <button className="button-back"onClick={() => navigate(-1)}>
      <img src="/back-svgrepo-com.svg" alt="Back" width="40" height="40" />
      </button>
      
    </header>
  );
};

export default Header;
