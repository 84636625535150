import { Link } from "react-router-dom";
import varanasi from "./Varanasi.jpg";

const Varanasi = () => {
    const locationURL = 'https://www.google.com/maps/place/%D0%92%D0%B0%D1%80%D0%B0%D0%BD%D0%B0%D1%81%D1%96,+%D0%A3%D1%82%D1%82%D0%B0%D1%80-%D0%9F%D1%80%D0%B0%D0%B4%D0%B5%D1%88,+%D0%86%D0%BD%D0%B4%D1%96%D1%8F/@25.3207626,82.9911089,12z/data=!3m1!4b1!4m6!3m5!1s0x398e2db76febcf4d:0x68131710853ff0b5!8m2!3d25.3176452!4d82.9739144!16zL20vMDFqOTIy?entry=ttu';


    const openMap = (e) => {
      e.preventDefault();
      window.open(locationURL, '_blank');
    };

    return ( 
        <main>
            <ul className="container-example-photo">
                <li className="container-item">  
                <img src={varanasi} alt="Varanasi" style={{ width: '100%' }}/>
                </li>
                <li><figcaption className="photo-description"><span className="free-text">Free location</span></figcaption>
                </li>
                <li className="container-item">
          <a href={locationURL} rel="noopener noreferrer" onClick={openMap}>
            <img src="/location-4-svgrepo-com.svg" alt="Location Icon" width="40" height="40" /> 
          </a>
        </li>
                <li className="container-list-buttons">
                    <div className="main-container">
                        <button className="button-connect"> <Link to="/connect"><span className="button-text">Connect</span></Link></button>
                        <button className="button-payment"> <Link to="/payment"><span className="button-text">Payment</span></Link></button>
                        
                    </div>
                </li>
            </ul>
        </main>
    );
}

export default Varanasi;
