import { Link } from "react-router-dom";

const Indonesia = () => {
    return ( 
    <main>
        <div className="main-container">
            <button> <Link to="/countries/indonesia/TanahLot"><span className="button-text">Tanah Lot</span></Link>
            </button>
            <button> <Link to="/countries/indonesia/Yogyakarta"><span className="button-text">Yogyakarta</span></Link>
            </button>
                        
        </div>
    </main> );
}
 
export default Indonesia;