// import { Link } from "react-router-dom";


const Contacts = () => {
    return ( 
        <main>
            <div className="contacts">
                <ul className="social-icons">
                    <li>
                    <a href="https://youtube.com/@DistanceVideo?si=eUVq4Q2xp1fMh6v3" target="_blank" rel="noopener noreferrer">
    {/* Вставте шлях до вашого SVG-файлу в атрибут src */}
    <img src="/youtube-2-logo-svgrepo-com.svg" alt="YouTube Icon" width="60" height="60" />
</a>
                    </li>
                    <li>
                    <a href="https://www.instagram.com/distance_video/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA%3D%3D" target="_blank" rel="noopener noreferrer">
                            {/* Використовуйте тег <img> з прямим шляхом до SVG-файлу */}
                            <img src="/instagram-1-svgrepo-com.svg" alt="Instagram Icon" width="60" height="60"/>
                        </a>
                    </li>
                    <li>
                    <a href="https://www.facebook.com/distance.video/" target="_blank" rel="noopener noreferrer">
    {/* Вставте шлях до вашого SVG-файлу в атрибут src */}
    <img src="/facebook-1-svgrepo-com.svg" alt="Facebook Icon" width="60" height="60" />
</a>
                    </li>
                    <li>
                    <a href="mailto:distance.v.office@gmail.com" target="_blank" rel="noopener noreferrer">
    {/* Вставте шлях до вашого SVG-файлу в атрибут src */}
    <img src="/email-mail-svgrepo-com.svg" alt="Email Icon" width="60" height="60" />
</a>
                    </li>
                    <li>
                    <a href="viber://chat?number=номер_телефону" target="_blank" rel="noopener noreferrer">
    {/* Вставте шлях до вашого SVG-файлу в атрибут src */}
    <img src="/viber-3-logo-svgrepo-com.svg" alt="Viber Icon" width="60" height="70" />
</a>
</li>
                </ul>
            </div>
            
        </main>
     );
}
 
export default Contacts;


