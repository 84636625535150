import { Link } from "react-router-dom";

const Home = () => {
    return ( <main>
        
        <div className="main-container">
            <button className="button-countries"> <Link to="/countries/countries"><span className="button-text">Countries</span></Link>
            </button>
            <button className="button-payment"> <Link to="/payment"><span className="button-text">Payment</span></Link>
            </button>
            <button className="button-gallery"> <Link to="/gallery"><span className="button-text">Gallery</span></Link>
            </button>
        </div>
    </main> );
}
 
export default Home;