import { Link } from "react-router-dom";
import bursatil from "./Bursatil.jpg";

const Bursatil = () => {
    const locationURL = 'https://www.google.com/maps/place/Centro+Bursatil/@19.4293712,-99.1641358,15z/data=!4m6!3m5!1s0x85d1fecd47ed8f23:0xed190bd3a4e0fd4e!8m2!3d19.4293712!4d-99.1641358!16s%2Fg%2F1n3dwb7n9?entry=ttu';


    const openMap = (e) => {
      e.preventDefault();
      window.open(locationURL, '_blank');
    };

    return ( 
        <main>
            <ul className="container-example-photo">
                <li className="container-item">  
                <img src={bursatil} alt="Bursatil" style={{ width: '100%' }}/>
                </li>
                <li><figcaption className="photo-description"><span className="free-text">Free location</span></figcaption>
                </li>
                <li className="container-item">
          <a href={locationURL} rel="noopener noreferrer" onClick={openMap}>
            <img src="/location-4-svgrepo-com.svg" alt="Location Icon" width="40" height="40" /> 
          </a>
        </li>
                <li className="container-list-buttons">
                    <div className="main-container">
                        <button className="button-connect"> <Link to="/connect"><span className="button-text">Connect</span></Link></button>
                        <button className="button-payment"> <Link to="/payment"><span className="button-text">Payment</span></Link></button>
                        
                    </div>
                </li>
            </ul>
        </main>
    );
}

export default Bursatil;
