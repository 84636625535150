import { Link } from "react-router-dom";

const England = () => {
    return ( 
    <main>
        <div className="main-container">
            <button> <Link to="/countries/england/BigBen"><span className="button-text">Big Ben</span></Link>
            </button>
            <button> <Link to="/countries/england/Stonehenge"><span className="button-text">Stonehenge</span></Link>
            </button>
            
            
        </div>
    </main> );
}
 
export default England;