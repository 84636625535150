import { Link } from "react-router-dom";

const Freelocations = () => {
    return ( 
    <main>
        <ul className="ul-freelocations">
            <li><p>The Distance Video project is made with Love for the planet Earth and people.</p>
                <h4>Example of locations for Distance Video cameras, which in our opinion are interesting for franchise buyers and these seats are not occupied.</h4></li>
                <li><h5>I apologize if you did not see your country and city in the list. We will be happy to add a new location based on your advice. We will be glad for feedback.</h5></li>
                <li className="li-freelocations">
                    <Link to="/countries/australia/HarborBridge"><span className="free-text">Australia Harbor Bridge</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/australia/Opera"><span className="free-text">Australia Opera</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/australia/PrincesBridge"><span className="free-text">Australia Princes Bridge</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/austria/FiveFingers"><span className="free-text">Austria Five Fingers</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/austria/Hallstatt"><span className="free-text">Austria Hallstatt</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/brazil/Christ"><span className="free-text">Brazil Christ</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/brazil/Iguazu"><span className="free-text">Brazil Iguazu</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/canada/Niagara"><span className="free-text">Canada Niagara</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/canada/Banff"><span className="free-text">Canada Banff</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/china/GreatWall"><span className="free-text">China Great Wall</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/china/SanqingMountain"><span className="free-text">China Sanqing Mountain</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/china/Shanghai"><span className="free-text">China Shanghai</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/china/TianmenMountain"><span className="free-text">China Tianmen Mountain</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/dubai/MuseumFuture"><span className="free-text">Dubai Museum Future</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/dubai/BurjAlArab"><span className="free-text">Dubai Burj Al Arab</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/england/BigBen"><span className="free-text">England BigBen</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/england/Stonehenge"><span className="free-text">England Stonehenge</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/france/Louvre"><span className="free-text">France Louvre</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/france/EiffelTower"><span className="free-text">France Eiffel Tower</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/greece/Navagio"><span className="free-text">Greece Navagio</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/greece/Parthenon"><span className="free-text">Greece Parthenon</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/india/TajMahal"><span className="free-text">India TajMahal</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/india/Varanasi"><span className="free-text">India Varanasi</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/india/Jaipur"><span className="free-text">India Jaipur</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/indonesia/TanahLot"><span className="free-text">Indonesia Tanah Lot</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/indonesia/Yogyakarta"><span className="free-text">Indonesia Yogyakarta</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/italy/Venice"><span className="free-text">Italy Venice</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/italy/Pisa"><span className="free-text">Italy Pisa</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/italy/Rome"><span className="free-text">Italy Rome</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/japan/Fuji"><span className="free-text">Japan Fuji</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/japan/TokyoShibuia"><span className="free-text">Japan TokyoShibuia</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/malaysia/KualaLumpur"><span className="free-text">Malaysia Kuala Lumpur</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/malaysia/LangkawiSkyBridge"><span className="free-text">Malaysia Sky Bridge</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/mexico/Palenque"><span className="free-text">Mexico Palenque</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/mexico/Bursatil"><span className="free-text">Mexico Bursatil</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/morocco/LeatherDyers"><span className="free-text">Morocco leather dyers</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/morocco/Tangier"><span className="free-text">Morocco Tangier</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/morocco/HassanIIMosque"><span className="free-text">Morocco Hassan II Mosque</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/newzealand/Queenstown"><span className="free-text">New Zealand Queenstown</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/newzealand/Hobbiton"><span className="free-text">New Zealand Hobbiton</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/norway/Pregestulen"><span className="free-text">Norway Pregestulen</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/norway/Stegastain"><span className="free-text">Norway Stegastain</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/singapore/MarinaBaySands"><span className="free-text">Singapore Marina BaySands</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/singapore/ParkRoyal"><span className="free-text">Singapore Park Royal</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/spain/Cordova"><span className="free-text">Spain Cordova</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/spain/city-barcelona/Church"><span className="free-text">Spain Barcelona Church</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/spain/city-barcelona/Parkguell"><span className="free-text">Spain Barcelona Parkguell</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/switzerland/OberhofenCastle"><span className="free-text">Switzerland Oberhofen Castle</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/switzerland/Bern"><span className="free-text">Switzerland Bern </span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/thailand/AutthayaPark"><span className="free-text">Thailand Autthaya park </span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/thailand/WatRongKhun"><span className="free-text">Thailand Wat Rong Khun</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/turkey/Istanbul"><span className="free-text">Turkey Istanbul</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/turkey/Bosphorus"><span className="free-text">Turkey Bosphorus</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/usa/GrandCanyon"><span className="free-text">USA Grand Canyon</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/usa/city-california/Hollywood"><span className="free-text">USA Hollywood</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/usa/city-california/GoldenGateBridge"><span className="free-text">USA Golden Gate Bridge</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/usa/city-new-york/Freedom"><span className="free-text">USA Freedom</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/usa/city-new-york/TimesSquare"><span className="free-text">USA Times Square</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/usa/city-new-york/CentralPark"><span className="free-text">USA Central Park</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/usa/hawaii/AlohaTower"><span className="free-text">USA Hawaii Aloha Tower</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/usa/hawaii/DiamondHead"><span className="free-text">USA Hawaii Diamond Head</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/usa/hawaii/Kauai"><span className="free-text">USA Hawaii Kauai</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/vietnam/Buddha"><span className="free-text">Vietnam Buddha</span></Link>
                </li>
                <li className="li-freelocations">
                    <Link to="/countries/vietnam/GoldenBridge"><span className="free-text">Vietnam Golden Bridge</span></Link>
                </li>
                
                

            </ul>
    </main> );
}
 
export default Freelocations;