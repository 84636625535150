import { Link } from "react-router-dom";

const Profile = () => {
    return ( 
    <main>
        <div className="profile-fon">
        <ul className="ul-profile">
                <li className="li-profile">
                    <Link to="/instruction" className="a-text-footer"><span className="span-li-profile">Instruction</span></Link>
                </li> 
                
                <li className="li-profile">
                    <Link to="/registrationForm" className="a-text-footer"><span className="span-li-profile">Registration Form</span></Link>
                </li>
                <li className="li-profile">
                    <Link to="/contacts" className="a-text-footer"><span className="span-li-profile">Contacts</span></Link>
                </li>
                
                <li className="li-profile">
                    <Link to="/franchise" className="a-text-footer"><span className="span-li-profile">Franchise</span></Link>
                </li> 
                <li className="li-profile">
                    <Link to="/freelocations" className="a-text-footer"><span className="span-li-profile">Free Locations for franchisees</span></Link>
                </li> 
                <li className="li-profile">
                    <Link to="/informationabout" className="a-text-footer"><span className="span-li-profile">About launch DV</span></Link>
                </li>
            </ul>
            </div>
    </main> );
}
 
export default Profile;