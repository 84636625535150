import { Link } from "react-router-dom";

const Italy = () => {
    return ( 
    <main>
        <div className="main-container">
            <button> <Link to="/countries/italy/Venice"><span className="button-text">Venice</span></Link>
            </button>
            <button> <Link to="/countries/italy/Pisa"><span className="button-text">Pisa</span></Link>
            </button>
            <button> <Link to="/countries/italy/Rome"><span className="button-text">Rome</span></Link>
            </button>
            
        </div>
    </main> );
}
 
export default Italy;