// import { Link } from "react-router-dom";

const Franchise = () => {
    return ( 
        <main>
            <div className="franchise">
            
            
                <p>The Distance Video project is made with Love for the planet Earth and people.</p>
                <p>Join the Distance Video project.</p>

                     
                
                <h3>Franchise information</h3>
            <ol>
                <li>
                A Distance Video franchise gives you the right to use the Distance Video app to generate income.
                </li>
                <li>
                The cost of the franchise is  333$ (This amount is not refundable. It is possible to exchange for 123 videos in the Distance Video application at any location in the world).
                </li>
                <li>
                The franchise is valid for 18 months.
                </li>
                <li>
                After the expiration of the agreement, there may be various options for extending or revising the terms of cooperation, as well as the possibility of concluding a new agreement.
                </li>
                <li>
                The franchise covers 1 location with a diameter of 350 meters. The center of the location is where the client is standing.
                </li>
                <li>
                No more than 3 cameras can be placed and connected to the Distance Video application per 1 location.
                </li>
                <li>
                You can reserve a location before the start of the Distance Video project. For this you need:
                <p>a. Pay the franchise fee</p>  
                <p>b. Take a photo or a short video of the location where the camera will be installed and send it to us.</p>  
                <p>c. We will place this photo in our application with the inscription: booked, opening soon.</p>
                </li>
                <li>
                Later, you need to follow our recommendation: 
                <p>a. buy a video camera in your convenient hardware store, or order it via the Internet (we will name the model);</p>
                <p>b. install it at the location;</p>
                <p>c. connect to the Internet;</p>
                <p>d. will remotely connect the camera to the Distance Video application.</p>
                </li>
                
                <li>
                Information on video cameras:
                <p>a. We are working on the selection of video cameras for the application. The priority is video quality and convenience when connecting to our program.</p>  
                <p>b. In the future, we want to make the video shoot with a turn and capture a larger panorama.</p>  
                </li>
                
                <li>
                The cost of the video camera may depend on the camera model.
                </li>
                <li>
                Video payment procedure:
                <p>a. The customer pays the full cost of the video to the franchisor's account through the Distance Video application.</p>  
                <p>b. The franchisee automatically receives its payment from the franchisor minus the royalty from the amount paid by the client.</p>  
                </li>
                <li>
                Promotions are carried out by agreement between the franchisor and the franchisee.
                </li>
                <li>
                Technical work on the Distance Video application is carried out at the expense of the franchisor.
                </li>
                <li>
                Technical work related to the video camera is carried out at the expense of the franchisee.
                </li>
                <li>
                The cost of the franchise includes technical support for the Distance Video application.
                </li>
                <li>
                The cost of the franchise includes updates to the Distance Video application, which will be released until the end of the franchise.
                </li>
                <li>
                The franchisee must keep the camera (lens) clean, and in case of a technical malfunction, call technical support in a timely manner.
                </li>
                <li>
                The franchisee must pay on time for the electricity and internet necessary for the operation of the camera.
                </li>
                <li>
                The franchisee has no right to transfer his right to franchise to other persons.
                </li>
                <li>
                The franchisor has the right to terminate the franchise agreement and disconnect the camera from the application in case of non-fulfillment of the terms of the franchise agreement.
                </li>

            </ol>




                <h3> Financial calculation for the franchise owner</h3>
               
                <p>The cost of 1 video for the client is 2 dollars 70 cents  </p>
                <p>The royalty fee is 0.45$ for 1 video </p>
                <p>Franchisee expenses of 100 $ per month are possible.(80 $ rent of space for the camera,
                   20 $ electricity and internet.)                    
                  </p>
                  <p>It can be assumed that 20 videos will be sold per day, then in a month it is 600 videos. At the same time, the franchise owner's earnings are about 2 $ per 1 video.</p>
                  <p>You can reserve a location for a video camera by paying the franchise before the launch of the Distance Video project.</p>
                  <p>All appeals should be made by e-mail: distance.v.office@gmail.com</p>
            </div>
        </main>

     );
}
 
export default Franchise;