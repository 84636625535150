import { Link } from "react-router-dom";

const Ukraine = () => {
    return ( 
    <main>
        <div className="main-container">
            <button className="button-kyiv"> <Link to="/countries/ukraine/city-kyiv/Kyiv"><span className="button-text">Kyiv</span></Link>
            </button>
            
        </div>
    </main> );
}
 
export default Ukraine;