import { Link } from "react-router-dom";

const Hawaii = () => {
    return ( <main>
        <div className="main-container">
            <button > <Link to="/countries/usa/hawaii/AlohaTower"><span className="button-text">Aloha Tower</span></Link>
            </button>
            <button > <Link to="/countries/usa/hawaii/DiamondHead"><span className="button-text">Diamond Head</span></Link>
            </button>
            <button > <Link to="/countries/usa/hawaii/Kauai"><span className="button-text">Kauai</span></Link>
            </button>
                                    
        </div>
    </main> );
}
 
export default Hawaii;