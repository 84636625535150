import { Link } from "react-router-dom";

const India = () => {
    return ( 
    <main>
        <div className="main-container">
            <button> <Link to="/countries/india/TajMahal"><span className="button-text">TajMahal</span></Link>
            </button>
            <button> <Link to="/countries/india/Varanasi"><span className="button-text">Varanasi</span></Link>
            </button>
            <button> <Link to="/countries/india/Jaipur"><span className="button-text">Jaipur</span></Link>
            </button>
            
        </div>
    </main> );
}
 
export default India;