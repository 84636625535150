import { Link } from "react-router-dom";

const Switzerland = () => {
    return ( 
    <main>
        <div className="main-container">
            <button> <Link to="/countries/switzerland/OberhofenCastle"><span className="button-text">Oberhofen Castle</span></Link>
            </button>
            <button> <Link to="/countries/switzerland/Bern"><span className="button-text">Bern</span></Link>
            </button>
            
        </div>
    </main> );
}
 
export default Switzerland;