import { Link } from "react-router-dom";
import parkguell from "./Parkguell.jpg";

const Parkguell = () => {
    const locationURL = 'https://www.google.com/maps/place/%D0%9F%D0%B0%D1%80%D0%BA+%D2%90%D1%83%D0%B5%D0%BB%D1%8C/@41.4144948,2.1526945,15z/data=!4m6!3m5!1s0x12a4a2ae52d441ab:0x899a0ba01aaace58!8m2!3d41.4144948!4d2.1526945!16zL20vMG52cw?entry=ttu';


    const openMap = (e) => {
      e.preventDefault();
      window.open(locationURL, '_blank');
    };

    return ( 
        <main>
            <ul className="container-example-photo">
                <li className="container-item">  
                <img src={parkguell} alt="Parkguell" style={{ width: '100%' }}/>
                </li>
                <li><figcaption className="photo-description"><span className="free-text">Free location</span></figcaption>
                </li>
                <li className="container-item">
          <a href={locationURL} rel="noopener noreferrer" onClick={openMap}>
            <img src="/location-4-svgrepo-com.svg" alt="Location Icon" width="40" height="40" /> 
          </a>
        </li>
                <li className="container-list-buttons">
                    <div className="main-container">
                        <button className="button-connect"> <Link to="/connect"><span className="button-text">Connect</span></Link></button>
                        <button className="button-payment"> <Link to="/payment"><span className="button-text">Payment</span></Link></button>
                                           </div>
                </li>
            </ul>
        </main>
    );
}

export default Parkguell;
