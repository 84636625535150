import { Link } from "react-router-dom";

const Mexico = () => {
    return ( 
    <main>
        <div className="main-container">
            <button> <Link to="/countries/mexico/Palenque"><span className="button-text">Palenque</span></Link>
            </button>
            <button> <Link to="/countries/mexico/Bursatil"><span className="button-text">Bursatil</span></Link>
            </button>
            
            
        </div>
    </main> );
}
 
export default Mexico;