import { Link } from "react-router-dom";

const Greece = () => {
    return ( 
    <main>
        <div className="main-container">
            <button> <Link to="/countries/greece/Navagio"><span className="button-text">Navagio</span></Link>
            </button>
            <button> <Link to="/countries/greece/Parthenon"><span className="button-text">Parthenon</span></Link>
            </button>
            
            
        </div>
    </main> );
}
 
export default Greece;