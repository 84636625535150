import { Link } from "react-router-dom";
import hallstatt from "./Hallstatt.jpg";

const Hallstatt = () => {
    const locationURL = 'https://www.google.com/maps/place/%D0%93%D0%B0%D0%BB%D1%8C%D1%88%D1%82%D0%B0%D1%82,+%D0%90%D0%B2%D1%81%D1%82%D1%80%D1%96%D1%8F/@47.53447,13.61063,12z/data=!3m1!4b1!4m6!3m5!1s0x4771366f6e414663:0x16c165596a26c1ad!8m2!3d47.5622342!4d13.6492617!16zL20vMDI3Ml96?entry=ttu';


    const openMap = (e) => {
      e.preventDefault();
      window.open(locationURL, '_blank');
    };

    return ( 
        <main>
            <ul className="container-example-photo">
                <li className="container-item">  
                <img src={hallstatt} alt="Hallstatt" style={{ width: '100%' }}/>
                </li>
                <li><figcaption className="photo-description"><span className="free-text">Free location</span></figcaption>
                </li>
                <li className="container-item">
          <a href={locationURL} rel="noopener noreferrer" onClick={openMap}>
            <img src="/location-4-svgrepo-com.svg" alt="Location Icon" width="40" height="40" /> 
          </a>
        </li>
                <li className="container-list-buttons">
                    <div className="main-container">
                        <button className="button-connect"> <Link to="/connect"><span className="button-text">Connect</span></Link></button>
                        <button className="button-payment"> <Link to="/payment"><span className="button-text">Payment</span></Link></button>
                        
                    </div>
                </li>
            </ul>
        </main>
    );
}

export default Hallstatt;
