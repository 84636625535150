import { Link } from "react-router-dom";

const Austria = () => {
    return ( 
    <main>
        <div className="main-container">
            <button> <Link to="/countries/austria/FiveFingers"><span className="button-text">Five Fingers</span></Link>
            </button>
            <button> <Link to="/countries/austria/Hallstatt"><span className="button-text">Hallstatt</span></Link>
            </button>
                        
        </div>
    </main> );
}
 
export default Austria;