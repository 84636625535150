import { Link } from "react-router-dom";

const USA = () => {
    return ( 
    <main>
        <div className="main-container">
            <button> <Link to="/countries/usa/city-california/California"><span className="button-text">California</span></Link>
            </button>
            <button> <Link to="/countries/usa/city-new-york/NewYork"><span className="button-text">New York</span></Link>
            </button>
            <button> <Link to="/countries/usa/GrandCanyon"><span className="button-text">Grand Canyon</span></Link>
            </button>
            <button> <Link to="/countries/usa/hawaii/Hawaii"><span className="button-text">Hawaii</span></Link>
            </button>
                        
        </div>
    </main> );
}
 
export default USA;