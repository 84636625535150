import { Link } from "react-router-dom";

const Brazil = () => {
    return ( 
    <main>
        <div className="main-container">
            <button> <Link to="/countries/brazil/Christ"><span className="button-text">Christ</span></Link>
            </button>
            <button> <Link to="/countries/brazil/Iguazu"><span className="button-text">Iguazu</span></Link>
            </button>
            
            
        </div>
    </main> );
}
 
export default Brazil;