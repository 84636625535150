import { Link } from "react-router-dom";

const Singapore = () => {
    return ( 
    <main>
        <div className="main-container">
            <button> <Link to="/countries/singapore/MarinaBaySands"><span className="button-text">Marina Bay Sands</span></Link>
            </button>
            <button> <Link to="/countries/singapore/ParkRoyal"><span className="button-text">Park Royal</span></Link>
            </button>
            
            
        </div>
    </main> );
}
 
export default Singapore;