import { Link } from "react-router-dom";
import marinabaysands from "./MarinaBaySands.png";

const MarinaBaySands = () => {
    const locationURL = 'https://www.google.com/maps/place/Marina+Bay+Sands/@1.2837575,103.8591065,15z/data=!4m9!3m8!1s0x31da19ee4cc09203:0x26c9afefa555dd7!5m2!4m1!1i2!8m2!3d1.2837575!4d103.8591065!16zL20vMGRkOTAz?entry=ttu';


    const openMap = (e) => {
      e.preventDefault();
      window.open(locationURL, '_blank');
    };

    return ( 
        <main>
            <ul className="container-example-photo">
                <li className="container-item">  
                <img src={marinabaysands} alt="MarinaBaySands" style={{ width: '100%' }}/>
                </li>
                <li><figcaption className="photo-description"><span className="free-text">Free location</span></figcaption>
                </li>
                <li className="container-item">
          <a href={locationURL} rel="noopener noreferrer" onClick={openMap}>
            <img src="/location-4-svgrepo-com.svg" alt="Location Icon" width="40" height="40" /> 
          </a>
        </li>
                <li className="container-list-buttons">
                    <div className="main-container">
                        <button className="button-connect"> <Link to="/connect"><span className="button-text">Connect</span></Link></button>
                        <button className="button-payment"> <Link to="/payment"><span className="button-text">Payment</span></Link></button>
                        
                    </div>
                </li>
            </ul>
        </main>
    );
}

export default MarinaBaySands;
