import { Link } from "react-router-dom";

const Japan = () => {
    return ( 
    <main>
        <div className="main-container">
            <button> <Link to="/countries/japan/Fuji"><span className="button-text">Fuji</span></Link>
            </button>
            <button> <Link to="/countries/japan/TokyoShibuia"><span className="button-text">Tokyo Shibuia</span></Link>
            </button>
         
                    
        </div>
    </main> );
}
 
export default Japan;