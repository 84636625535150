import { Link } from "react-router-dom";
import hollywood from "./Hollywood.png";

const Hollywood = () => {
    const locationURL = 'https://www.google.com/maps/place/%D0%93%D0%BE%D0%BB%D0%BB%D1%96%D0%B2%D1%83%D0%B4,+%D0%9B%D0%BE%D1%81-%D0%90%D0%BD%D0%B4%D0%B6%D0%B5%D0%BB%D0%B5%D1%81,+%D0%9A%D0%B0%D0%BB%D1%96%D1%84%D0%BE%D1%80%D0%BD%D1%96%D1%8F,+%D0%A1%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B5%D0%BD%D1%96+%D0%A8%D1%82%D0%B0%D1%82%D0%B8+%D0%90%D0%BC%D0%B5%D1%80%D0%B8%D0%BA%D0%B8/@34.0937233,-118.4088793,12z/data=!3m1!4b1!4m6!3m5!1s0x80c2bf07045279bf:0xf67a9a6797bdfae4!8m2!3d34.0928092!4d-118.3286614!16zL20vMGYyd2o?entry=ttu';


    const openMap = (e) => {
      e.preventDefault();
      window.open(locationURL, '_blank');
    };

    return ( 
        <main>
            <ul className="container-example-photo">
                <li className="container-item">  
                <img src={hollywood} alt="Hollywood" style={{ width: '100%' }}/>
                </li>
                <li><figcaption className="photo-description"><span className="free-text">Free location</span></figcaption>
                </li>
                <li className="container-item">
          <a href={locationURL} rel="noopener noreferrer" onClick={openMap}>
            <img src="/location-4-svgrepo-com.svg" alt="Location Icon" width="40" height="40" /> 
          </a>
        </li>
                <li className="container-list-buttons">
                    <div className="main-container">
                        <button className="button-connect"> <Link to="/connect"><span className="button-text">Connect</span></Link></button>
                        <button className="button-payment"> <Link to="/payment"><span className="button-text">Payment</span></Link></button>
                        
                    </div>
                </li>
            </ul>
        </main>
    );
}

export default Hollywood;
