import { Link } from "react-router-dom";

const Vietnam = () => {
    return ( 
    <main>
        <div className="main-container">
            <button> <Link to="/countries/vietnam/Buddha"><span className="button-text">Buddha</span></Link>
            </button>
            <button> <Link to="/countries/vietnam/GoldenBridge"><span className="button-text">Golden Bridge</span></Link>
            </button>
            
            
        </div>
    </main> );
}
 
export default Vietnam;