import { Link } from "react-router-dom";

const Thailand = () => {
    return ( 
    <main>
        <div className="main-container">
            <button> <Link to="/countries/thailand/AutthayaPark"><span className="button-text">Autthaya park</span></Link>
            </button>
            <button> <Link to="/countries/thailand/WatRongKhun"><span className="button-text">Wat Rong Khun</span></Link>
            </button>
            
        </div>
    </main> );
}
 
export default Thailand;