import { Link } from "react-router-dom";

const Dubai = () => {
    return ( 
    <main>
        <div className="main-container">
            <button> <Link to="/countries/dubai/MuseumFuture"><span className="button-text">Museum Future</span></Link>
            </button>
            <button> <Link to="/countries/dubai/BurjAlArab"><span className="button-text">Burj Al Arab</span></Link>
            </button>
            
        </div>
    </main> );
}
 
export default Dubai;