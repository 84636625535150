import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer id="fixed-footer">
            <div className="ul-footer">
            <Link to="/profile" >
          <img src="/profile-svgrepo-com (1).svg" alt="Profile" width="50" height="50" />
       </Link>
            </div>
        </footer>
    );
}

export default Footer;
