import { Link } from "react-router-dom";

const Barcelona = () => {
    return ( <main>
        <div className="main-container">
            <button > <Link to="/countries/spain/city-barcelona/Church"><span className="button-text">Holy Family Church</span></Link></button>
            <button > <Link to="/countries/spain/city-barcelona/Parkguell"><span className="button-text">Park Guell</span></Link></button>
                        
        </div>
    </main> );
}
 
export default Barcelona;